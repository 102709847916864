import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Featured Case Studies`}</h1>
    <p>{`The following case studies represent end to end implementations of the technology, with a diverse array of institutions and organizations filling various `}<a parentName="p" {...{
        "href": "/roles",
        "title": "roles"
      }}>{`roles`}</a>{`.`}</p>
    <h3><a parentName="h3" {...{
        "href": "/Case-studies/2014-vTaiwan"
      }}>{`vTaiwan`}</a></h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` A mass movement demanded upgrades to democracy and won, resulting in Polis' first national-scale deployment.`}<br />{`
`}<strong parentName="p">{`When:`}</strong>{` 2014 - ongoing`}<br />{`
`}<strong parentName="p">{`Where:`}</strong>{` Taiwan`}<br />{`
`}<strong parentName="p">{`Size:`}</strong>{` 200,000+`}<br /></p>
    <details>
  <summary><b>› Details:</b></summary>
The mass protests of the 2014 Sunflower Movement publicly demonstrated that better group decision-making was possible through facilitation and demanded the government of Taiwan upgrade their democratic processes accordingly. The government conceded, and requested that the g0v (pronounced "gov zero") civic tech community create a scalable listening process for national use. The resulting multiphase, design thinking-informed, collective intelligence process became known as vTaiwan ("v" for virtual), and today vTaiwan contains the longest running, national-scale implementation of Polis. Accordingly, Taiwan has generated more case studies on using Polis in the process of creating national legislation than any other country.
      <br /><br />
      <p><a parentName="p" {...{
          "href": "/Case-studies/2014-vTaiwan"
        }}>{`Learn More`}</a></p>
    </details>
    <hr></hr>
    <h3><a parentName="h3" {...{
        "href": "/Case-studies/2022-Austria-Klimarat"
      }}>{`The Klimarat in Austria`}</a></h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` A national climate assembly used Polis to involve thousands more people.`}<br />{`
`}<strong parentName="p">{`When:`}</strong>{` 2022`}<br />{`
`}<strong parentName="p">{`Where:`}</strong>{` Austria`}<br />{`
`}<strong parentName="p">{`Size:`}</strong>{` 5,000+`}<br /></p>
    <details>
  <summary><b>› Details:</b></summary>
The 84 citizen members of Klimarat, Austria’s National Climate Council, in pursuit of their task to answer “What do we have to do today in order to live in a climate-friendly future tomorrow?” used the main instance of Pol.is to reach over 5,000 Austrians in an online engagement known as "The Climate Council asks Austria." The resulting crowd-tested recommendations were submitted to Parliament in July 2022.
      <br /><br />
      <p><a parentName="p" {...{
          "href": "/Case-studies/2022-Austria-Klimarat"
        }}>{`Learn More`}</a></p>
    </details>
    <hr></hr>
    <h3><a parentName="h3" {...{
        "href": "/Case-studies/2021-Uruguay-national-referendum"
      }}>{`Uruguay and the 2021 national referendum`}</a></h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` Polis used alongside an overstuffed national referendum to reveal which issues are deterministic and which contain breakthrough consensus.`}<br />{`
`}<strong parentName="p">{`When:`}</strong>{` 2020 - 2021`}<br />{`
`}<strong parentName="p">{`Where:`}</strong>{` Uruguay`}<br />{`
`}<strong parentName="p">{`Size:`}</strong>{` 16,000+`}<br /></p>
    <details>
  <summary><b>› Details:</b></summary>
In 2020, a newly elected government in Uruguay put their entire party platform into a single bill marked “Urgent,” a special designation meaning that if agreement is not reached by lawmakers within 30 days a bill will be automatically passed. This sprawling and difficult to understand bill contained over 500 articles, from self defense, to education, to the oversight of education, to public safety, to prison overcrowding. Outraged at this, fully a quarter of the country’s total population signed a petition for this bill to instead be put to National Referendum. The referendum still contained the entire bill with over 500 articles. To support the most informed vote possible, a team of 13, including engineers, communications professionals, and data science faculty and staff at Uruguay’s National University set up Polis alongside the referendum to look for deeper understanding than “yes or no.” 16,499 people participated by commenting and casting over 295,557 votes. The referendum happened in early 2021 and, unfortunately for the movement attempting to overturn this bill, the vote was lost by less than 1%. The Polis results showed, among other things, that even if the ultimate divider was public safety, there were other topics and issues where surprising points of consensus broke through. These topics could serve as bridges between groups of people who, before their experience with a wiki survey, were on completely different sides.
      <br /><br />
      <p><a parentName="p" {...{
          "href": "/Case-studies/2021-Uruguay-national-referendum"
        }}>{`Learn More`}</a></p>
    </details>
    <hr></hr>
    <h3><a parentName="h3" {...{
        "href": "/Case-studies/2020-UNDP-South+S-E-Asia"
      }}>{`Youth Conversations in Bhutan, Pakistan, Timor Leste`}</a></h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` United Nations Development Programme (UNDP) conducted the "largest digital engagement to date in the Global South" with youth.`}<br />{`
`}<strong parentName="p">{`When:`}</strong>{` 2020-21`}<br />{`
`}<strong parentName="p">{`Where:`}</strong>{` Bhutan, Pakistan, Timor Leste`}<br />{`
`}<strong parentName="p">{`Size:`}</strong>{` 30,000+`}<br /></p>
    <details>
  <summary><b>› Details:</b></summary>
To hear from youth on their priorities for climate action leading up to COP26 in Fall 2021, UNDP Bangkok Regional Hub reached out to country offices in Bhutan, Pakistan, and Timor Leste to run a large-scale engagement. For outreach, UNDP used a technology partner to send over 10M SMS messages to phones of youth 15-19 years of age, and provided support from the Cognition Company. The message read: “raise your voice and let us know your thoughts: youthconversations.org” and was signed by the United Nations Development Programme. An overarching goal was to model how governments and legislatures could better consult their citizens on policy issues.
      <br /><br />
      <p><a parentName="p" {...{
          "href": "/Case-studies/2020-UNDP-South+S-E-Asia"
        }}>{`Learn More`}</a></p>
    </details>
    <hr></hr>
    <h3><a parentName="h3" {...{
        "href": "/Case-studies/2016-New-Zealand-Scoop-Hivemind"
      }}>{`New Zealand's HiveMind`}</a></h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` National newspaper in New Zealand sets up "HiveMind", helps government agency develop policy.`}<br />{`
`}<strong parentName="p">{`When:`}</strong>{` 2016 - 2019`}<br />{`
`}<strong parentName="p">{`Where:`}</strong>{` New Zealand`}<br />{`
`}<strong parentName="p">{`Size:`}</strong>{` 1700+`}<br /></p>
    <details>
  <summary><b>› Details:</b></summary>
Two facilitators at New Zealand’s Public Engagement Projects (PEP) partnered with a feisty national newspaper to run six Polis conversations in as many years on issues ranging from taxation to sugar policy to Universal Basic Income. The method eventually attracted the attention of a staffer at a national government agency and the method was successfully used to help create national policy.
      <br /><br />
      <p><a parentName="p" {...{
          "href": "/Case-studies/2016-New-Zealand-Scoop-Hivemind.mdx"
        }}>{`Learn more`}</a></p>
    </details>
    <hr></hr>
    <h3><a parentName="h3" {...{
        "href": "/Case-studies/2020-philippines-MBC"
      }}>{`Philippines builds on Taiwan model`}</a></h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` Makati Business Club works with mayors on municipal policy consultations and trains government staff to run Polis on their own.`}<br />{`
`}<strong parentName="p">{`When:`}</strong>{` 2020 - ongoing`}<br />{`
`}<strong parentName="p">{`Where:`}</strong>{` Philippines`}<br />{`
`}<strong parentName="p">{`Size:`}</strong>{` 3000+`}<br /></p>
    <details>
  <summary><b>› Details:</b></summary>
In 2020, inspired by Taiwan’s successful pandemic response, Makati Business Club interviewed Audrey Tang for a “Freedom and Democracy Zine” and thereafter began piloting use of Polis with local governmental units. With buy-in from mayors across the Philippines thanks to the organization’s standing as business leaders, Makati Business Club has led 5 consultations in 6 cities reaching over 3000 people.
      <br /><br />
      <p><a parentName="p" {...{
          "href": "/Case-studies/2020-philippines-MBC"
        }}>{`Learn more`}</a></p>
    </details>
    <hr></hr>
    <h3><a parentName="h3" {...{
        "href": "/Case-studies/2018-kentucky"
      }}>{`Townhall meeting in Kentucky turns tables on polarization`}</a></h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` Residents of Bowling Green, Kentucky, used Polis to find agreement on local issues despite politicians' attempt to sow discord.`}<br />{`
`}<strong parentName="p">{`When:`}</strong>{` 2018`}<br />{`
`}<strong parentName="p">{`Where:`}</strong>{` Kentucky, USA`}<br />{`
`}<strong parentName="p">{`Size:`}</strong>{` 2000`}<br /></p>
    <details>
  <summary><b>› Details:</b></summary>
When a medium sized city in Kentucky was ravaged and divided by national hot button issues, town residents used Polis to identify local issues on which there was consensus, gain an understanding of their collective will, and then use the mapping provided by Polis to hold their officials accountable to act on that consensus. This case had very high participation rates relative to total population size: 2000 out of 65000 of the population participated, or 3%.
      <br /><br />
      <p><a parentName="p" {...{
          "href": "/Case-studies/2018-kentucky"
        }}>{`Learn more`}</a></p>
    </details>
    <hr></hr>
    <h3><a parentName="h3" {...{
        "href": "/Case-studies/2020-UK-Demos"
      }}>{`DEMOS pairs Polis with nationwide representative sampling`}</a></h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` Demos conducts first Polis conversation with a nationally representative sample.`}<br />{`
`}<strong parentName="p">{`When:`}</strong>{` 2020`}<br />{`
`}<strong parentName="p">{`Where:`}</strong>{` United Kingdom`}<br />{`
`}<strong parentName="p">{`Size:`}</strong>{` 997`}<br /></p>
    <details>
  <summary><b>› Details:</b></summary>
Post-BREXIT, UK-based thinktank Demos conducted national conversation on the attitudes toward data-driven political campaigning, and created an excellent literature review on the political theory basis for deliberation at scale.
      <br /><br />
      <p><a parentName="p" {...{
          "href": "/Case-studies/2020-UK-Demos"
        }}>{`Learn more`}</a></p>
    </details>
    <hr></hr>
    <h3><a parentName="h3" {...{
        "href": "/Case-studies/2018-germany-aufstehen"
      }}>{`Aufstehen conducts world's largest Polis conversation`}</a></h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` German political party “Aufstehen” brings most participants ever into a Polis conversation to form the party's platform.`}<br />{`
`}<strong parentName="p">{`When:`}</strong>{` 2018`}<br />{`
`}<strong parentName="p">{`Where:`}</strong>{` Germany`}<br />{`
`}<strong parentName="p">{`Size:`}</strong>{` 33,547 participants`}<br /></p>
    <details>
  <summary><b>› Details:</b></summary>Aufstehen, meaning “stand-up”; is an emergent German political party founded in Summer 2018 in response to the growth of right-wing political parties. A Polis conversation was run in the Fall of 2018 to gain understanding on how coalition building could be used to build broad consensus, and inform the creation of the party’s political platform in accordance with the will of its members. This demonstrated how technology can bring about new and innovative ideas to the democratic process. At time of writing, this conversation retains the world record for largest number of participants in a single Polis conversation.
      <br /><br />
      <p><a parentName="p" {...{
          "href": "/Case-studies/2018-germany-aufstehen"
        }}>{`Learn more`}</a></p>
    </details>
    <hr></hr>
    <h3><a parentName="h3" {...{
        "href": "/Case-studies/2023-greece-airbnb"
      }}>{`Greek Cities Use Polis for Thorny AirBnB Issue`}</a></h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` Residents in two Greek cities—Athens and Thessaloniki—used Polis to brainstorm practical solutions to the AirBnB issue, and find common ground for regulation. `}<br />{`
`}<strong parentName="p">{`When:`}</strong>{` 2023 `}<br />{`
`}<strong parentName="p">{`Where:`}</strong>{` Greece `}<br />{`
`}<strong parentName="p">{`Size:`}</strong>{` 944 `}<br /></p>
    <details>
  <summary><b>› Details:</b></summary> In Greek cities, as in other European cities, the mushrooming of Airbnb properties has negatively affected local residents in numerous ways. It has exacerbated housing shortages and inflated rents; affected local affordability and transformed residential neighborhoods into tourist-centric zones. At the same time, one-third of Greece’s GDP comes from tourism, and a considerable number of local residents work in the tourism industry. This contradiction has fueled deep polarization on the issue. A nonprofit called AstyLab (City Lab, in Greek) used Polis to bring together a diverse group of city residents, to brainstorm practical solutions and find convergence, with the end goal of regulating AirBnb in Greece.
      <br /><br />
      <p><a parentName="p" {...{
          "href": "/Case-studies/2023-greece-airbnb"
        }}>{`Learn more`}</a></p>
    </details>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      